
import * as cloudflareRuntime$NpSBtDky7a from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as ipxStaticRuntime$LG0a2DaAad from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [
    "storage.googleapis.com"
  ],
  "alias": {
    "/lumaAssets": "https://storage.googleapis.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$NpSBtDky7a, defaults: {"baseURL":"https://luma-delikatessen.ch/"} },
  ['ipxStatic']: { provider: ipxStaticRuntime$LG0a2DaAad, defaults: {"isStatic":true} }
}
        